import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateTelegramIntegrationRequest = z.object({
  projectId: z.string(),
  telegramApiKey: z.string().optional().nullable(),
});

const UpdateProjctConfigIntegrationsRespone = z.object({});

export type UpdateTelegramIntegrationRequest = z.infer<
  typeof UpdateTelegramIntegrationRequest
>;

export const UpdateTelegramIntegrationDefinition = getFirebaseFunctionDefinition({
  path: "/integrations/telegra/update",
  reqType: UpdateTelegramIntegrationRequest,
  resType: UpdateProjctConfigIntegrationsRespone,
  isAuthRequired: true,
});
