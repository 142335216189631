import { z } from "zod";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Language } from "./Language";
import { Timestamp } from "./Timestamp";

export const ChatProvider = z.enum(["Telegram", "Whatsapp", "Web"]);
export const Chat = z.object({
  id: z.string(),
  userId: z.string().optional(), // auth user id for web chats
  guestName: z.string().optional(),
  stayId: z.string().optional(),
  projectId: z.string(),
  chatMessageCount: z.number().optional(),
  externalChatId: z.string().optional(),
  externalUserId: z.string().optional(),
  externalUserName: z.string().optional(),
  chatProvider: ChatProvider,
  updatedAt: Timestamp.optional(),
  createdAt: Timestamp,
  language: Language.default("en-GB").optional(),
});

export type Chat = z.infer<typeof Chat>;
export type ChatProvider = z.infer<typeof ChatProvider>;

export const ChatCollection = (
  projectId?: string
): CollectionDescriptor<Chat> => ({
  path: `projectConfigs/${projectId}/chats`,
  schema: Chat,
});
