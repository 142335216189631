import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const DeleteApiToolConfigRequest = z.object({
  apiToolConfigId: z.string(),
  projectId: z.string(),
  
});

const DeleteApiToolConfigResponse = z.object({});


export type DeleteApiToolConfigRequest = z.infer<typeof DeleteApiToolConfigRequest>;
export type DeleteApiToolConfigResponse = z.infer<typeof DeleteApiToolConfigResponse>;

export const DeleteApiToolConfigDefinition = getFirebaseFunctionDefinition({
  path: "/projects/api-tool/delete",
  reqType: DeleteApiToolConfigRequest,
  resType: DeleteApiToolConfigResponse,
  isAuthRequired: true,
});
