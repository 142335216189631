import { z } from "zod";
import { WABAErrorCodeSchema } from "./WhatsappErrors";
import {
  WhatsappMessageType,
  WhatsappReactionMessage,
} from "./WhatsappMessage";

const WebhookContactSchema = z.object({
  wa_id: z.string(),
  profile: z.object({
    name: z.string(),
  }),
});

const WebhookErrorSchema = z.object({
  code: WABAErrorCodeSchema,
  title: z.string(),
  message: z.string(),
  error_data: z.object({
    details: z.string(),
  }),
});

const WebhookMediaSchema = z.object({
  caption: z.string().optional(),
  filename: z.string(),
  ha256: z.string(),
  mime_type: z.string(),
  id: z.string(),
});

export const WhatsappWebhookMessage = z.object({
  type: z.union([
    WhatsappMessageType,
    z.literal("system"),
    z.literal("unknown"),
  ]),
  timestamp: z.string(),
  audio: z
    .object({
      id: z.string(),
      mime_type: z.string(),
    })
    .optional(),
  button: z
    .object({
      payload: z.string(),
      text: z.string(),
    })
    .optional(),
  context: z
    .object({
      forwarded: z.boolean(),
      frequently_forwarded: z.boolean(),
      from: z.string(),
      id: z.string(),
      referred_product: z
        .object({
          catalog_id: z.string(),
          product_retailer_id: z.string(),
        })
        .optional(),
    })
    .optional(),
  document: WebhookMediaSchema.optional(),
  errors: z.array(WebhookErrorSchema).optional(),
  from: z.string(),
  id: z.string(),
  identity: z
    .object({
      acknowledged: z.boolean(),
      created_timestamp: z.string(),
      hash: z.string(),
    })
    .optional(),
  image: WebhookMediaSchema.optional(),
  interactive: z
    .object({
      type: z.object({
        button_reply: z
          .object({
            id: z.string(),
            title: z.string(),
          })
          .optional(),
        list_reply: z
          .object({
            id: z.string(),
            title: z.string(),
            description: z.string(),
          })
          .optional(),
      }),
    })
    .optional(),
  order: z
    .object({
      catalog_id: z.string(),
      text: z.string(),
      product_items: z.array(
        z.object({
          product_retailer_id: z.string(),
          quantity: z.string(),
          item_price: z.string(),
          currency: z.string(),
        })
      ),
    })
    .optional(),
  referral: z
    .object({
      source_url: z.string(),
      source_type: z.enum(["ad", "post"]),
      source_id: z.string(),
      headline: z.string(),
      body: z.string(),
      media_type: z.string(),
      image_url: z.string().optional(),
      video_url: z.string().optional(),
      thumbnail_url: z.string().optional(),
      ctwa_clid: z.string(),
    })
    .optional(),
  sticker: z
    .object({
      mime_type: z.literal("image/webp"),
      sha256: z.string(),
      id: z.string(),
      animated: z.boolean(),
    })
    .optional(),
  text: z
    .object({
      body: z.string(),
    })
    .optional(),
  video: WebhookMediaSchema.optional(),
  reaction: WhatsappReactionMessage.optional(),
});

export type WhatsappWebhookMessage = z.infer<typeof WhatsappWebhookMessage>;

const WhatsappWebhookStatusSchema = z.object({
  id: z.string(),
  // biz_opaque_callback_data: z.string(),
  // pricing: z.object({
  //   category: z.enum([
  //     "authentication",
  //     "marketing",
  //     "utility",
  //     "service",
  //     "referral_conversion",
  //   ]),
  //   pricing_model: z.string(),
  // }),
  // conversation: z.object({
  //   id: z.string(),
    // origin: z.object({
    //   type: z.enum([
    //     "authentication",
    //     "marketing",
    //     "utility",
    //     "service",
    //     "business_initiated",
    //     "customer_initiated",
    //     "referral_conversation",
    //   ]),
    // }),
    // expiration_timestamp: z.string().optional(),
  // }).optional(),
  recipient_id: z.string(),
  status: z.enum(["delivered", "failed", "read", "sent"]),
  timestamp: z.string(),
});

const WhatsappWebhookMetadata = z.object({
  display_phone_number: z.string(),
  phone_number_id: z.string(),
});

const WhatsappWebhookChange = z.object({
  value: z.object({
    messaging_product: z.literal("whatsapp"),
    metadata: WhatsappWebhookMetadata.optional(),
    errors: z.array(WebhookErrorSchema).optional(),
    contacts: z.array(WebhookContactSchema).optional(),
    messages: z.array(WhatsappWebhookMessage).optional(),
    // statuses: z.array(WhatsappWebhookStatusSchema).optional(),
  }),
  field: z.literal("messages"),
});

export const WhatsappWebhookRequest = z.object({
  object: z.string(),
  entry: z.array(
    z.object({
      id: z.string(),
      changes: z.array(WhatsappWebhookChange),
    })
  ),
});

export type WhatsappWebhookRequest = z.infer<typeof WhatsappWebhookRequest>;

const x = {
  path: "/projects/:projectId/whatsapp/message/receive/",
  request: {
    object: "whatsapp_business_account",
    entry: [
      {
        id: "101886679671701",
        changes: [
          {
            value: {
              messaging_product: "whatsapp",
              metadata: {
                display_phone_number: "15550717228",
                phone_number_id: "106766479178015",
              },
              contacts: [{ profile: { name: "Zall" }, wa_id: "4915737654395" }],
              messages: [
                {
                  from: "4915737654395",
                  id: "wamid.HBgNNDkxNTczNzY1NDM5NRUCABIYFDNBODI3ODFFQkQ0MzY0RkIyMDkwAA==",
                  timestamp: "1713857784",
                  text: { body: "Can you send me an image?" },
                  type: "text",
                },
              ],
            },
            field: "messages",
          },
        ],
      },
    ],
  },
  severity: "INFO",
  message: "call /projects/:projectId/whatsapp/message/receive/",
};
