import { z } from "zod";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Timestamp } from "./Timestamp";

export const ApaleoIntegration = z.object({
  id: z.string(),
  propertyId: z.string().optional(),
  webhookUrl: z.string().optional(),
  subscriptionId: z.string().optional(),
  error: z.string().optional(),
  accessToken: z.string().optional(),
  refreshToken: z.string().optional(),
  idToken: z.string().optional(),
  tokenExpiresAt: Timestamp.optional(),
  authCode: z.string().optional(), // used to get Access Token during the initial connect
  codeScopes: z.string().optional(), // scopes of the granted authCode
  tokenScopes: z.string().optional(), // scopes of the granted authToken
});

export type ApaleoIntegration = z.infer<typeof ApaleoIntegration>;

export const ApaleoIntegrationCollection = (
  projectId?: string
): CollectionDescriptor<ApaleoIntegration> => ({
  path: `projectConfigs/${projectId}/integrations`,
  schema: ApaleoIntegration,
});
