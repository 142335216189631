import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const ReceiveWebMessageRequest = z.object({
  projectId: z.string(),
  chatId: z.string(),
  text: z.string(),
});

const ReceiveWebMessageResponse = z.object({
  chatMessageId: z.string(),
});

export type ReceiveWebMessageRequest = z.infer<
  typeof ReceiveWebMessageRequest
>;

export const ReceiveWebMessageDefinition = getFirebaseFunctionDefinition({
  path: "/web/message/receive/",
  reqType: ReceiveWebMessageRequest,
  resType: ReceiveWebMessageResponse,
  isAuthRequired: true,
});
