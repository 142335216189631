import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { AttachmentType, SupportedMimeType } from "./AttachmentType";
import { Dimensions } from "./Dimensions";

export const Attachment = z.object({
  attachmentId: z.string().optional(),
  attachmentContext: z.string().optional(),
  filePath: z.string(),
  type: AttachmentType,
  mimeType: SupportedMimeType.optional(),
  dimensions: Dimensions.optional(),
});

export type Attachment = z.infer<typeof Attachment>;

export function isAttachment(obj: unknown): obj is Attachment {
  return isSchema(obj, Attachment, "Attachment");
}
