import { z } from "zod";
import { Dimensions } from "../types/Dimensions";
import { FileType } from "../types/FileType";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateAttachmentConfigRequest = z.object({
  attachmentConfigId: z.string(),
  projectId: z.string(),
  originalFileName: z.string(),
  filePath: z.string(),
  context: z.string(),
  type: FileType,
  dimensions: Dimensions.optional(),
});

const UpdateAddAttachmentConfigResponse = z.object({
  attachmentConfigId: z.string(),
});

export type UpdateAddAttachmentConfigRequest = z.infer<
  typeof UpdateAttachmentConfigRequest
>;

export const UpdateAttachmentConfigDefinition = getFirebaseFunctionDefinition({
  path: "/project-configs/attachment-configs/update",
  reqType: UpdateAttachmentConfigRequest,
  resType: UpdateAddAttachmentConfigResponse,
  isAuthRequired: true,
});
