import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Timestamp } from "./Timestamp";
import { UserRole } from "./UserRole";

export const User = z.object({
  id: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  managedProjectIds: z.array(z.string()).optional(),
  role: UserRole.optional(), 
  createdAt: Timestamp,
});

export type User = z.infer<typeof User>;

export function isUser(obj: unknown): obj is User {
  return isSchema(obj, User, "User");
}

export const UsersCollection: CollectionDescriptor<User> = {
  path: `users`,
  schema: User,
};
