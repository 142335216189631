import { z } from "zod";
import { Language } from "../types/Language";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateStayRequest = z.object({
  stayId: z.string(),
  projectId: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  roomNumber: z.string().optional(),
  checkinDate: z.string().optional(),
  checkoutDate: z.string().optional(),
  guestId: z.string().optional(),
  language: Language.optional(),
});

const UpdateStayResponse = z.object({});

export type UpdateStayRequest = z.infer<typeof UpdateStayRequest>;
export type UpdateStayResponse = z.infer<typeof UpdateStayResponse>;

export const UpdateStayDefinition = getFirebaseFunctionDefinition({
  path: "/projects/stay/update",
  reqType: UpdateStayRequest,
  resType: UpdateStayResponse,
  isAuthRequired: true,
});
