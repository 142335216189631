import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const TelegramMessage = z.object({
  message_id: z.number(),
  from: z.object({
    id: z.number(),
    is_bot: z.boolean(),
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    username: z.string().optional(),
    language_code: z.string().optional(),
  }),
  chat: z.object({
    id: z.number(),
    first_name: z.string().optional(),
    username: z.string().optional(),
    type: z.string(),
  }),
  date: z.number(),
  text: z.string().optional(),
});

const ReceiveTelegramMessageRequest = z.object({
  update_id: z.number(),
  message: TelegramMessage.optional(),
  edited_message: TelegramMessage.optional(),
});

const ReceiveTelegramMessageResponse = z.object({});

export type ReceiveTelegramMessageRequest = z.infer<
  typeof ReceiveTelegramMessageRequest
>;

export const ReceiveTelegramMessageDefinition = getFirebaseFunctionDefinition({
  path: "/projects/:projectId/telegram/message/receive/",
  reqType: ReceiveTelegramMessageRequest,
  resType: ReceiveTelegramMessageResponse,
  isAuthRequired: false,
});
