import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const ConnectApaleoRequest = z.object({
  projectId: z.string(),
  authCode: z.string(),
  scopes: z.string(),
  redirectUrl: z.string(),
});

const ConnectProjctConfigsRespone = z.object({});

export type ConnectApaleoRequest = z.infer<typeof ConnectApaleoRequest>;

export const ConnectApaleoDefinition = getFirebaseFunctionDefinition({
  path: "/integrations/apaleo/connect",
  reqType: ConnectApaleoRequest,
  resType: ConnectProjctConfigsRespone,
  isAuthRequired: true,
});
