import { z } from "zod";
import { Language } from "./Language";

export const PROMPT_CONTEXT_KEYS = [
  "date",
  "time",
  "weekday",
  "timezone",
  "checkin_date",
  "checkout_date",
  "room_number",
  "language",
  "username",
] as const;

export const PromptContext = z.object({
  date: z.string(),
  time: z.string(),
  weekday: z.string(),
  timezone: z.string(),
  checkin_date: z.string().optional(),
  checkout_date: z.string().optional(),
  room_number: z.string().optional(),
  language: Language.optional(),
  username: z.string().optional(),
});

export const PromptConstextKeys = z.enum(PROMPT_CONTEXT_KEYS);

export type PromptContextKeys = z.infer<typeof PromptConstextKeys>;
export type PromptContext = z.infer<typeof PromptContext>;
