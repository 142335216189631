import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateWhatsappIntegrationRequest = z.object({
  projectId: z.string(),
  whatsappApiKey: z.string().nullable(),
  whatsappPhoneId: z.string().nullable(),
  whatsappVerificationKey: z.string().nullable(),
  whatsappWABAID: z.string().nullable(),
});

const UpdateWhatsappIntegrationsRespone = z.object({});

export type UpdateWhatsappIntegrationRequest = z.infer<
  typeof UpdateWhatsappIntegrationRequest
>;

export const UpdateWhatsappIntegrationDefinition =
  getFirebaseFunctionDefinition({
    path: "/integrations/whatsapp",
    reqType: UpdateWhatsappIntegrationRequest,
    resType: UpdateWhatsappIntegrationsRespone,
    isAuthRequired: true,
  });
