import { z } from "zod";

export const WhatsappIntegration = z.object({
    phoneNumber: z.string().optional(),
    webhookUrl: z.string().optional(),
    phoneId: z.string().optional(),
    apiKey: z.string().optional(),
    verificationKey: z.string().optional(),
    WABAID: z.string().optional(),
});

export type WhatsappIntegration = z.infer<typeof WhatsappIntegration>;
