import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const DeleteAttachmentConfigRequest = z.object({
  attachmentConfigId: z.string(),
  projectId: z.string(),
});

const DeleteAttachmentConfigResponse = z.object({});

export type RemoveAttachmentConfigRequest = z.infer<
  typeof DeleteAttachmentConfigRequest
>;

export const DeleteAttachmentConfigDefinition =
  getFirebaseFunctionDefinition({
    path: "/project-configs/attachment-configs/delete",
    reqType: DeleteAttachmentConfigRequest,
    resType: DeleteAttachmentConfigResponse,
    isAuthRequired: true,
  });
