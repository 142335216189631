import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Attachment } from "./Attachment";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Timestamp } from "./Timestamp";

export const ChatMessage = z.object({
  id: z.string(),
  text: z.string(),
  senderType: z.enum(["USER", "AI", "MANAGER"]),
  senderId: z.string().optional(),
  attachments: z.array(Attachment).optional(),
  createdAt: Timestamp,
});

export const InitialChatMessage = ChatMessage.omit({
  id: true,
  createdAt: true,
});

export type ChatMessage = z.infer<typeof ChatMessage>;
export type InitialChatMessage = z.infer<typeof InitialChatMessage>;

export function isChatMessage(obj: unknown): obj is ChatMessage {
  return isSchema(obj, ChatMessage, "ChatMessage");
}

export const ChatMessageCollection = (
  projectId?: string,
  chatId?: string
): CollectionDescriptor<ChatMessage> => ({
  path: `projectConfigs/${projectId}/chats/${chatId}/messages`,
  schema: ChatMessage,
});
