import { z } from "zod";
import { Dimensions } from "../types/Dimensions";
import { FileType } from "../types/FileType";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AddAttachmentConfigRequest = z.object({
  projectId: z.string(),
  originalFileName: z.string(),
  filePath: z.string(),
  context: z.string(),
  type: FileType,
  dimensions: Dimensions.optional(),
});

const AddAttachmentConfigResponse = z.object({
  attachmentConfigId: z.string(),
});

export type AddAttachmentConfigRequest = z.infer<
  typeof AddAttachmentConfigRequest
>;

export const AddAttachmentConfigDefinition = getFirebaseFunctionDefinition({
  path: "/project-configs/attachment-configs/add",
  reqType: AddAttachmentConfigRequest,
  resType: AddAttachmentConfigResponse,
  isAuthRequired: true,
});
