import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { InitialChatMessage } from "./ChatMessage";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { ElevenLabsModel } from "./ElevenLabsModel";
import { LlmType } from "./LlmType";
import { Timestamp } from "./Timestamp";

export const ProjectConfig = z.object({
  id: z.string(),
  name: z.string().optional(), // TODO make required
  profilePictureFilePath: z.string().optional(), // TODO make required
  llmType: LlmType,
  llmTemperature: z.number().min(0),
  chatCount: z.number(),
  chatPrompt: z.string(),
  initialChatMessages: z.array(InitialChatMessage).optional(),
  createdAt: Timestamp,
  whatsappPhoneNumber: z.string().optional(),
  whatsappWebhookUrl: z.string().optional(),
  whatsappPhoneId: z.string().optional(),
  whatsappApiKey: z.string().optional(),
  whatsappVerificationKey: z.string().optional(),
  whatsappWABAID: z.string().optional(),
  telegramApiKey: z.string().optional(),
  telegramBotName: z.string().optional(),
  telegramWebhookUrl: z.string().optional(),
  timezone: z.string().optional(),
});

export type ProjectConfig = z.infer<typeof ProjectConfig>;

export function isProjectConfig(obj: unknown): obj is ProjectConfig {
  return isSchema(obj, ProjectConfig, "ProjectConfig");
}

export const ProjectConfigsCollection: CollectionDescriptor<ProjectConfig> = {
  path: `projectConfigs`,
  schema: ProjectConfig,
};
