import { z } from "zod";
import { Language } from "../types/Language";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const StartWebChatRequest = z.object({
  projectId: z.string(),
  stayId: z.string().optional(),
  language: Language.optional(),
});

const StartWebChatResponse = z.object({
  chatId: z.string(),
});

export type StartWebChatRequest = z.infer<typeof StartWebChatRequest>;

export const StartWebChatDefinition = getFirebaseFunctionDefinition({
  path: "/chats/web/start",
  reqType: StartWebChatRequest,
  resType: StartWebChatResponse,
  isAuthRequired: true,
});
