import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateProjectRequest = z.object({
  name: z.string(),
});

const CreateProjectResponse = z.object({
  projectId: z.string(),
});

export type CreateProjectRequest = z.infer<typeof CreateProjectRequest>;

export const CreateProjectDefinition = getFirebaseFunctionDefinition({
  path: "/projects/create",
  reqType: CreateProjectRequest,
  resType: CreateProjectResponse,
  isAuthRequired: true,
});
