import { z } from "zod";

// export const LLAMA_TYPES = ["llama2-7b", "llama2-13b", "llama2-70b"] as const;
// export const LlamaType = z.enum(LLAMA_TYPES);
// export type LlamaType = z.infer<typeof LlamaType>;

export const GPT_TYPES = ["gpt-3.5-turbo", "gpt-4"] as const;
export const GptType = z.string().transform((value) => {
  return GPT_TYPES.includes(value as any) ? value : GPT_TYPES[0];
});
export type GptType = z.infer<typeof GptType>;

export const GEMINI_TYPES = ["gemini-1.0-pro"] as const;
export const GeminiType = z.string().transform((value) => {
  return GEMINI_TYPES.includes(value as any) ? value : GPT_TYPES[0];
});
export type GeminiType = z.infer<typeof GptType>;

export const LLM_TYPES = [...GPT_TYPES] as const;
export const LlmType = z.string().transform((value) => {
  return LLM_TYPES.includes(value as any) ? value : LLM_TYPES[0];
});
export type LlmType = z.infer<typeof LlmType>;
