import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Timestamp } from "./Timestamp";

export const ToolCallRecord = z.object({
  toolType: z.enum(["KnowledgeBaseTool", "FileAttachmentTool", "ApiTool"]),
  toolName: z.string(),
  toolDescription: z.string(),
  calledAt: Timestamp.optional(),
  parameters: z.record(
      z.string(),
      z.string().or(z.number()).or(z.boolean())
    ).optional(),
  toolOutput: z.string().optional(),
  error: z.string().optional(),
  
});

export type ToolCallRecord = z.infer<typeof ToolCallRecord>;

export function isToolCallRecord(
  obj: unknown
): obj is ToolCallRecord {
  return isSchema(obj, ToolCallRecord, "ToolCallRecord");
}
