import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateMessageFromManagerRequest = z.object({
  projectId: z.string(),
  chatId: z.string(),
  managerId: z.string(),
  text: z.string(),
});

const CreateMessageFromManagerResponse = z.object({});

export type CreateMessageFromManagerRequest = z.infer<
  typeof CreateMessageFromManagerRequest
>;

export const CreateMessageFromManagerDefinition = getFirebaseFunctionDefinition(
  {
    path: "/messages/create/manager",
    reqType: CreateMessageFromManagerRequest,
    resType: CreateMessageFromManagerResponse,
    isAuthRequired: true,
  }
);
