import { z } from "zod";
import { WhatsappWebhookRequest } from "../types/WhatsappWebhook";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const ReceiveWhatsappMessageRequest = WhatsappWebhookRequest.optional();

const ReceiveWhatsappMessageResponse = z.object({
  chatId: z.string(),
});

export type ReceiveWhatsappMessageRequest = z.infer<
  typeof ReceiveWhatsappMessageRequest
>;

export const ReceiveWhatsappMessageDefinition = getFirebaseFunctionDefinition({
  path: "/projects/:projectId/whatsapp/message/receive/",
  reqType: ReceiveWhatsappMessageRequest,
  resType: ReceiveWhatsappMessageResponse,
  isAuthRequired: false,
});
