import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateWhatsappSharedIntegrationRequest = z.object({
  apiKey: z.string(),
  phoneId: z.string(),
  verificationKey: z.string(),
  WABAID: z.string(),
});

const UpdateWhatsappSharedIntegrationsRespone = z.object({});

export type UpdateWhatsappSharedIntegrationRequest = z.infer<
  typeof UpdateWhatsappSharedIntegrationRequest
>;

export const UpdateWhatsappSharedIntegrationDefinition =
  getFirebaseFunctionDefinition({
    path: "/integrations/whatsapp-shared",
    reqType: UpdateWhatsappSharedIntegrationRequest,
    resType: UpdateWhatsappSharedIntegrationsRespone,
    isAuthRequired: true,
  });
