import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";
import { KnowledgeDocument } from "../types/KnowledgeConfig";

const AddKnowledgeConfigRequest = z.object({
  knowledgeId: z.string().optional(),
  projectId: z.string(),
  title: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  documents: z.array(KnowledgeDocument),
  useEmbeddings: z.boolean(),
});

const AddKnowledgeConfigResponse = z.object({
  knowledgeId: z.string(),
});

export type AddKnowledgeConfigRequest = z.infer<
  typeof AddKnowledgeConfigRequest
>;
export type AddKnowledgeConfigResponse = z.infer<
  typeof AddKnowledgeConfigResponse
>;

export const AddKnowledgeConfigDefinition = getFirebaseFunctionDefinition({
  path: "/projects/knowledge/add",
  reqType: AddKnowledgeConfigRequest,
  resType: AddKnowledgeConfigResponse,
  isAuthRequired: true,
});
