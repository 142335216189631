import { z } from "zod";

export const WhatsappMessageType = z.enum([
  "audio",
  "document",
  "image",
  "video",
  "sticker",
  "text",
  "location",
]);

export const WhatsappMediaObject = z.object({
  id: z.string().optional(),
  link: z.string().optional(),
  caption: z.string().optional(),
  filename: z.string().optional(),
  provider: z.string().optional(),
});

export const WhatsappLocationMessage = z.object({
  longitude: z.string(),
  latitude: z.string(),
  name: z.string().optional(),
  address: z.string().optional(),
});

export const WhatsappTextMessage = z.object({
  body: z.string(),
  preview_url: z.boolean().optional(),
});

export const WhatsappReactionMessage = z.object({
  message_id: z.string(),
  emoji: z.string(),
});

export const WhatsappMessage = z.object({
  type: WhatsappMessageType.optional(),
  messaging_product: z.literal("whatsapp"),
  recipient_type: z.string().optional(), // Assuming any string is valid here
  // biz_opaque_callback_data: z.string().max(512),
  context: z
    .object({
      message_id: z.string(),
    })
    .optional(),
  to: z.string(),
  audio: WhatsappMediaObject.optional(),
  document: WhatsappMediaObject.optional(),
  image: WhatsappMediaObject.optional(),
  video: WhatsappMediaObject.optional(),
  sticker: WhatsappMediaObject.optional(),
  location: WhatsappLocationMessage.optional(),
  text: WhatsappTextMessage.optional(),
});

export type WhatsappMessage = z.infer<typeof WhatsappMessage>;
export type WhatsappMessageType = z.infer<typeof WhatsappMessageType>;
