import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateApiToolConfigRequest = z.object({
  apiToolConfigId: z.string(),
  projectId: z.string(),
  name: z.string(),
  description: z.string(),
  parameters: z.array(
    z.object({
      name: z.string(),
      type: z.string(),
      description: z.string(),
      required: z.boolean(),
      testValue: z.string(),
    })
  ),
  urlTemplate: z.string(),
  method: z.enum(["GET", "POST", "PUT", "DELETE"]),
  headers: z.array(
    z.object({
      name: z.string(),
      value: z.string(),
    })
  ),
  bodyTemplate: z.string(),
  responseTemplate: z.string(),
  enabled: z.boolean(),
  errorMessage: z.string(),
});

const UpateApiToolConfigResponse = z.object({
  apiToolConfigId: z.string(),
});

export type UpdateApiToolConfigRequest = z.infer<
  typeof UpdateApiToolConfigRequest
>;
export type UpdateApiToolConfigResponse = z.infer<
  typeof UpateApiToolConfigResponse
>;

export const UpdateApiToolConfigDefinition = getFirebaseFunctionDefinition({
  path: "/projects/api-tool/update",
  reqType: UpdateApiToolConfigRequest,
  resType: UpateApiToolConfigResponse,
  isAuthRequired: true,
});
