import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { FileType } from "./FileType";

export const IMAGE_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
] as const;
export const AUDIO_MIME_TYPES = ["audio/mp3", "audio/mpeg"] as const;
export const VIDEO_MIME_TYPES = ["video/mp4", "video/webm"] as const;
export const DOCUMENT_MIME_TYPES = ["text/plain", "application/pdf"] as const;

export const ATTACHMENT_TYPES = [
  FileType.Values.IMAGE,
  FileType.Values.AUDIO,
  FileType.Values.VIDEO,
  FileType.Values.DOCUMENT,
] as const;

export const AttachmentType = z.enum(ATTACHMENT_TYPES);

export const ImageMimeType = z.enum(IMAGE_MIME_TYPES);
export const AudioMimeType = z.enum(AUDIO_MIME_TYPES);
export const VideoMimeType = z.enum(VIDEO_MIME_TYPES);
export const DocumentMimeType = z.enum(DOCUMENT_MIME_TYPES);
export const SupportedMimeType = z.enum([
  ...IMAGE_MIME_TYPES,
  ...AUDIO_MIME_TYPES,
  ...VIDEO_MIME_TYPES,
  ...DOCUMENT_MIME_TYPES,
]);

export type AttachmentType = z.infer<typeof AttachmentType>;

export type ImageMimeType = z.infer<typeof ImageMimeType>;
export type AudioMimeType = z.infer<typeof AudioMimeType>;
export type VideoMimeType = z.infer<typeof VideoMimeType>;
export type DocumentMimeType = z.infer<typeof DocumentMimeType>;
export type SupportedMimeType = z.infer<typeof SupportedMimeType>;

export function isAttachmentType(obj: unknown): obj is AttachmentType {
  return isSchema(obj, AttachmentType, "AttachmentType");
}
