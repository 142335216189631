import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const DeleteApaleoIntegrationRequest = z.object({
  projectId: z.string(),
});

const DeleteProjctConfigIntegrationsRespone = z.object({});

export type DeleteApaleoIntegrationRequest = z.infer<
  typeof DeleteApaleoIntegrationRequest
>;

export const DeleteApaleoIntegrationDefinition = getFirebaseFunctionDefinition({
  path: "/integrations/apaleo/delete",
  reqType: DeleteApaleoIntegrationRequest,
  resType: DeleteProjctConfigIntegrationsRespone,
  isAuthRequired: true,
});
