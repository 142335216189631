import { z } from "zod";

export const PHASES = ["ALL", "UNKNOWN", "BEFORE_STAY", "DURING_STAY", "AFTER_STAY"] as const;
export const Phase = z
  .string()
  .transform((value) => {
    return PHASES.includes(value as any) ? value : PHASES[0];
  });

export type Phase = z.infer<typeof Phase>;
