import { z } from "zod";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { WhatsappIntegration } from "./WhatsappIntegration";

export const AppConfig = z.object({
  id: z.string(),
  projectPromptTemplate: z.string().default(""), // Default system message for a new projct
  globalPromptTemplate: z.string().default(""), // System mesasge used in every project.
  translatePromptTemplate: z.string().default(""), // Prompt template to translate a message.
  attachmentToolPromptTemplate: z.string().default(""), // Prompt to template for tha attachment tool.
  chatHistoryMessageLimit: z.number().default(10), // number of messages to add to the promt as history
  whatsappSharedIntegration: WhatsappIntegration.optional(),
});
export type AppConfig = z.infer<typeof AppConfig>;
export const AppConfigCollection: CollectionDescriptor<AppConfig> = {
  path: `appConfigs`,
  schema: AppConfig,
};