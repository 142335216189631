import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateAppConfigRequest = z.object({
  chatPromptTemplate: z.string(),
  globalPrompt: z.string(),
  chatPromptMessageLimit: z.number(),
  translatePromptTemplate: z.string(),
  attachmentToolPromptTemplate: z.string(),
});

const UpdateAppConfigResponse = z.object({});

export type UpdateAppConfigRequest = z.infer<typeof UpdateAppConfigRequest>;

export const UpdateAppConfigDefinition = getFirebaseFunctionDefinition({
  path: "/app-configs/update",
  reqType: UpdateAppConfigRequest,
  resType: UpdateAppConfigResponse,
  isAuthRequired: true,
});
