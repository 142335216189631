import { z } from "zod";
import { Dimensions } from "../types/Dimensions";
import { FileOwnerType } from "../types/FileOwnerType";
import { FileType } from "../types/FileType";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const MAX_IMAGE_SIZE = 5_000_000; // 5MB
const MAX_VIDEO_SIZE = 50_000_000; // 50MB
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];
const ACCEPTED_AUDIO_TYPES = ["audio/mp3", "audio/mpeg"];
const ACCEPTED_DOC_TYPES = ["text/plain", "application/pdf"];
const ACCEPTED_VIDEO_TYPES = ["video/mp4", "video/webm"];

const UploadFileRequest = z.object({
  ownerId: z.string().min(1),
  projectId: z.string().min(1),
  ownerType: FileOwnerType,
  file: z
    .object({
      name: z.string(),
      size: z.number(),
      mimeType: z.string(),
      content: z.string(), // base64 encoded
    })
    .refine((file) => {
      if (ACCEPTED_IMAGE_TYPES.includes(file.mimeType)) {
        return file.size <= MAX_IMAGE_SIZE;
      }

      if (ACCEPTED_AUDIO_TYPES.includes(file.mimeType)) {
        return true;
      }

      if (ACCEPTED_DOC_TYPES.includes(file.mimeType)) {
        return true;
      }
      if (ACCEPTED_VIDEO_TYPES.includes(file.mimeType)) {
        return file.size <= MAX_VIDEO_SIZE;
      }
    }, "Invalid format or file size."),
});

const UploadFileResponse = z.object({
  id: z.string(),
  ownerType: FileOwnerType,
  ownerId: z.string(),
  filePath: z.string(),
  type: FileType,
  dimensions: Dimensions.optional(),
});

export type UploadFileRequest = z.infer<typeof UploadFileRequest>;
export type UploadFileResponse = z.infer<typeof UploadFileResponse>;

export const UploadFileDefinition = getFirebaseFunctionDefinition({
  path: "/file/upload",
  reqType: UploadFileRequest,
  resType: UploadFileResponse,
  isAuthRequired: true,
});
