import { z } from "zod";
import { Phase } from "../types/Phase";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

export const PhaseConfig = z.object({
  id: z.string(),
  activeInPhases: z.array(Phase).default(["ALL"]),
});

export const SetPhasesRequest = z.object({
  projectId: z.string(),
  toolsPhases: z.array(PhaseConfig),
  knowledgePhases: z.array(PhaseConfig),
  attachmentPhases: z.array(PhaseConfig),
});

export const SetPhasesResponse = z.object({});

export type SetPhasesRequest = z.infer<typeof SetPhasesRequest>;
export type PhaseConfig = z.infer<typeof PhaseConfig>;

export const SetPhasesDefinition = getFirebaseFunctionDefinition({
  path: "/project-configs/phases/set",
  reqType: SetPhasesRequest,
  resType: SetPhasesResponse,
  isAuthRequired: true,
});
