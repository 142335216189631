import { z } from "zod";

export const WhatsappPhoneNumberResponseSchema = z.object({
  verified_name: z.string(),
  code_verification_status: z.enum(["NOT_VERIFIED", "EXPIRED"]),
  display_phone_number: z.string(),
  quality_rating: z.string(),
  platform_type: z.string(),

  throughput: z.object({
    level: z.string(),
  }),
  id: z.string(),
});

export type WhatsappPhoneNumberResponse = z.infer<
  typeof WhatsappPhoneNumberResponseSchema
>;
