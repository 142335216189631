import { z } from "zod";

export const StartCommand = z.object({
    stayId: z.string().optional(),
    projectId: z.string(),
    language: z.string(),
});

export type StartCommand = z.infer<typeof StartCommand>;

export function generateStartMessage(startCommand: StartCommand): string {
    const command = `${startCommand.stayId || ""}:${startCommand.language}:${startCommand.projectId}`;
    return Buffer.from(command)
        .toString("base64")
        .replace(/=+$/, "");
}

export function generateWhatsappUrl(phoneNumber: string | undefined, startMessage: string): string | undefined {
    return phoneNumber ? `https://wa.me/${phoneNumber}?text=/start ${startMessage}` : undefined;
}

export function generateTelegramUrl(botName: string | undefined, startMessage: string): string | undefined {
    return botName ? `https://t.me/${botName}?start=${startMessage}` : undefined;
}

export function generateWebUrl(messengerDomain: string, projectId: string, stayId?: string): string {
    return stayId ? `${messengerDomain}/projects/${projectId}/stays/${stayId}` : `${messengerDomain}/projects/${projectId}`;
}

function decodeBase64(base64String: string): string {
    return Buffer.from(base64String, "base64").toString("utf-8");
}

export function parseStartMessage(
    message: string
):
    | [isStartCommand: true, command: StartCommand]
    | [false, undefined] {


    if (message.startsWith("/start")) {
        const startStr = message.split(" ")[1];
        const messageDecoded = decodeBase64(startStr);
        const [stayId, language, projectId] = messageDecoded.split(":");

        const startCommand: StartCommand = {
            stayId,
            language,
            projectId,
        };

        return [true, startCommand];
    }
    return [false, undefined];
}

