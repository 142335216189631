import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ChatPromptMessage } from "./ChatPromptMessage";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { LlmType } from "./LlmType";
import { Phase } from "./Phase";
import { Timestamp } from "./Timestamp";
import { TokenUsage } from "./TokenUsage";
import { ToolCallRecord } from "./ToolCallRecord";

export const ChatPrompt = z.object({
  chatMessageId: z.string(),
  globalPrompt: z.string().optional(),
  projectPrompt: z.string().optional(),
  error: z.string().optional(),
  messages: z.array(ChatPromptMessage).optional(),
  userInput: z.string(),
  responseText: z.string().optional(),
  toolCalls: z.array(ToolCallRecord),
  createdAt: Timestamp,
  tokenUsage: TokenUsage.optional(),
  llmType: LlmType.optional(),
  llmTemperature: z.number().optional(),
  phase: Phase.optional(),
});

export type ChatPrompt = z.infer<typeof ChatPrompt>;

export function isChatPrompt(obj: unknown): obj is ChatPrompt {
  return isSchema(obj, ChatPrompt, "ChatPrompt");
}

export const ChatPromptsCollection = (
  projectId?: string,
  chatId?: string
): CollectionDescriptor<ChatPrompt> => ({
  path: `projectConfigs/${projectId}/chats/${chatId}/prompts`,
  schema: ChatPrompt,
});
