import { z } from "zod";
import { KnowledgeDocument } from "../types/KnowledgeConfig";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateKnowledgeConfigRequest = z.object({
  knowledgeId: z.string(),
  projectId: z.string(),
  title: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  documents: z.array(KnowledgeDocument),
  useEmbeddings: z.boolean(),
});

const UpateKnowledgeConfigResponse = z.object({
  knowledgeId: z.string(),
});

export type UpdateKnowledgeConfigRequest = z.infer<
  typeof UpdateKnowledgeConfigRequest
>;
export type UpdateKnowledgeConfigResponse = z.infer<
  typeof UpateKnowledgeConfigResponse
>;

export const UpdateKnowledgeConfigDefinition = getFirebaseFunctionDefinition({
  path: "/projects/knowledge/update",
  reqType: UpdateKnowledgeConfigRequest,
  resType: UpateKnowledgeConfigResponse,
  isAuthRequired: true,
});
