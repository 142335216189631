import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateApaleoIntegrationRequest = z.object({
  projectId: z.string(),
  propertyId: z.string(),
});

const UpdateProjctConfigIntegrationsRespone = z.object({});

export type UpdateApaleoIntegrationRequest = z.infer<
  typeof UpdateApaleoIntegrationRequest
>;

export const UpdateApaleoIntegrationDefinition = getFirebaseFunctionDefinition({
  path: "/integrations/apaleo/update",
  reqType: UpdateApaleoIntegrationRequest,
  resType: UpdateProjctConfigIntegrationsRespone,
  isAuthRequired: true,
});
