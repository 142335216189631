import { z } from "zod";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Timestamp } from "./Timestamp";

export const Project = z.object({
  id: z.string(),
  name: z.string(),
  profilePictureFilePath: z.string(),
  telegramBotName: z.string().optional(),
  whatsappPhoneNumber: z.string().optional(),
  createdAt: Timestamp,
});

export type Project = z.infer<typeof Project>;

export const ProjectsCollection: CollectionDescriptor<Project> = {
  path: `projects`,
  schema: Project,
};
