import { z } from "zod";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Phase } from "./Phase";
import { Timestamp } from "./Timestamp";

export const ApiToolConfig = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  parameters: z.array(
    z.object({
      name: z.string(),
      type: z.string(),
      description: z.string(),
      required: z.boolean(),
      testValue: z.string().optional(),
    })
  ),
  urlTemplate: z.string(),
  responseTemplate: z.string(),
  method: z.enum(["GET", "POST", "PUT", "DELETE"]),
  headers: z.array(
    z.object({
      name: z.string(),
      value: z.string(),
    })
  ),
  bodyTemplate: z.string(),
  createdAt: Timestamp,
  activeInPhases: z.array(Phase).default(["ALL"]),
  errorMessage: z.string().optional(),
});

export type ApiToolConfig = z.infer<typeof ApiToolConfig>;

export const ApiToolConfigsCollection = (
  projectId?: string
): CollectionDescriptor<ApiToolConfig> => ({
  path: `projectConfigs/${projectId}/apiToolConfigs`,
  schema: ApiToolConfig,
});
