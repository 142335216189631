import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const RemoveManagerRequest = z.object({
  projectId: z.string(),
  userId: z.string(),
});

const RemoveManagerResponse = z.object({});

export type RemoveManagerRequest = z.infer<typeof RemoveManagerRequest>;

export const RemoveManagerDefinition = getFirebaseFunctionDefinition({
  path: "/managers/remove",
  reqType: RemoveManagerRequest,
  resType: RemoveManagerResponse,
  isAuthRequired: true,
});
