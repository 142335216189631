import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AcceptInvitationRequest = z.object({
  projectId: z.string(),
  invitationId: z.string(),
  password: z.string(),
  email: z.string(),
});

const AcceptInvitationResponse = z.object({
  error: z.string().optional(),
});

export type AcceptInvitationRequest = z.infer<typeof AcceptInvitationRequest>;

export const AcceptInvitationDefinition = getFirebaseFunctionDefinition({
  path: "/invitations/accept",
  reqType: AcceptInvitationRequest,
  resType: AcceptInvitationResponse,
  isAuthRequired: true,
});
