import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const TestApiToolRequest = z.object({
  projectId: z.string(),
  apiToolConfigId: z.string(),
  parameters: z.array(
    z.object({
      name: z.string(),
      value: z.string().or(z.number()).or(z.boolean()),
    })
  ),
});

const TestApiToolResponse = z.object({
  response: z.string().optional(),
  error: z.string().optional(),
});

export type TestApiToolRequest = z.infer<typeof TestApiToolRequest>;

export const TestApiToolDefinition = getFirebaseFunctionDefinition({
  path: "/project-configs/api-tools/test",
  reqType: TestApiToolRequest,
  resType: TestApiToolResponse,
  isAuthRequired: true,
});
