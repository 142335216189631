import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Phase } from "./Phase";
import { Timestamp } from "./Timestamp";

export const KnowledgeDocument = z.object({ id: z.string(), text: z.string() });

export const KnowledgeConfig = z.object({
  id: z.string(),
  title: z.string(),
  useEmbeddings: z.boolean().optional(),
  description: z.string(),
  indexFile: z.string().optional(), // path to the folder with fiass index
  indexingCompleted: z.boolean().optional(),
  indexingError: z.string().optional(),
  enabled: z.boolean().optional(),
  createdAt: Timestamp,
  documents: z.array(KnowledgeDocument).optional().default([]),
  activeInPhases: z.array(Phase).default(["ALL"]),
});

export type KnowledgeConfig = z.infer<typeof KnowledgeConfig>;
export type KnowledgeDocument = z.infer<typeof KnowledgeDocument>;

export function isKnowledgeConfig(obj: unknown): obj is KnowledgeConfig {
  return isSchema(obj, KnowledgeConfig, "KnowledgeConfig");
}

export const KnowledgeConfigsCollection = (
  projectId?: string
): CollectionDescriptor<KnowledgeConfig> => ({
  path: `projectConfigs/${projectId}/knowledgeConfigs`,
  schema: KnowledgeConfig,
});
