import { z } from "zod";
import { InitialChatMessage } from "../types/ChatMessage";
import { LlmType } from "../types/LlmType";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateProjectRequest = z.object({
  projectId: z.string(),
  name: z.string(),
  profilePictureFilePath: z.string(),
  chatPrompt: z.string(),
  initialChatMessages: z.array(InitialChatMessage),
  llmType: LlmType,
  timezone: z.string(),
  llmTemperature: z.number().min(0),
});

const UpdateProjectResponse = z.object({});

export type UpdateProjectRequest = z.infer<typeof UpdateProjectRequest>;

export const UpdateProjectDefinition = getFirebaseFunctionDefinition({
  path: "/admin/projects/update",
  reqType: UpdateProjectRequest,
  resType: UpdateProjectResponse,
  isAuthRequired: true,
});
