import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Timestamp } from "./Timestamp";

export const Invitation = z.object({
  id: z.string(),
  email: z.string(),
  projectId: z.string(),
  userId: z.string().optional(),
  createdAt: Timestamp,
  acceptedAt: Timestamp.optional(),
  // Can be removed. only for development
  invitationLink: z.string(),
});

export type Invitation = z.infer<typeof Invitation>;

export function isInvitation(obj: unknown): obj is Invitation {
  return isSchema(obj, Invitation, "Invitation");
}

export const InvitationsCollection = (
  projectId?: string
): CollectionDescriptor<Invitation> => ({
  path: `projectConfigs/${projectId}/invitations`,
  schema: Invitation,
});
