import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { SupportedMimeType } from "./AttachmentType";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Dimensions } from "./Dimensions";
import { FileOwnerType } from "./FileOwnerType";
import { FileType } from "./FileType";
import { Timestamp } from "./Timestamp";

export const File = z.object({
  id: z.string(),
  ownerType: FileOwnerType,
  ownerId: z.string(),
  filePath: z.string(),
  createdAt: Timestamp,
  mimeType: SupportedMimeType,
  type: FileType,
  dimensions: Dimensions.optional(),
});

export type File = z.infer<typeof File>;

export function isFile(obj: unknown): obj is File {
  return isSchema(obj, File, "File");
}

export const FilesCollection = (
  projectId?: string
): CollectionDescriptor<File> => ({
  path: `projectConfigs/${projectId}/files`,
  schema: File,
});
