import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { AttachmentType, SupportedMimeType } from "./AttachmentType";
import { CollectionDescriptor } from "./CollectionDescriptor";
import { Dimensions } from "./Dimensions";
import { Phase } from "./Phase";
import { Timestamp } from "./Timestamp";

export const AttachmentConfig = z.object({
  id: z.string(),
  originalFileName: z.string(),
  filePath: z.string(),
  context: z.string(),
  type: z.enum([
    AttachmentType.Values.IMAGE,
    AttachmentType.Values.AUDIO,
    AttachmentType.Values.VIDEO,
    AttachmentType.Values.DOCUMENT,
  ]),
  mimeType: SupportedMimeType.optional(),
  dimensions: Dimensions.optional(),
  createdAt: Timestamp,
  activeInPhases: z.array(Phase).default(["ALL"]),
});

export type AttachmentConfig = z.infer<typeof AttachmentConfig>;

export function isAttachmentConfig(obj: unknown): obj is AttachmentConfig {
  return isSchema(obj, AttachmentConfig, "AttachmentConfig");
}

export const AttachmentConfigsCollection = (
  projectId?: string
): CollectionDescriptor<AttachmentConfig> => ({
  path: `projectConfigs/${projectId}/attachmentConfigs`,
  schema: AttachmentConfig,
});
