import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const DeleteInvitationRequest = z.object({
  projectId: z.string(),
  invitationId: z.string(),
});

const DeleteInvitationResponse = z.object({});

export type DeleteInvitationRequest = z.infer<typeof DeleteInvitationRequest>;

export const DeleteInvitationDefinition = getFirebaseFunctionDefinition({
  path: "/invitations/delete",
  reqType: DeleteInvitationRequest,
  resType: DeleteInvitationResponse,
  isAuthRequired: true,
});
