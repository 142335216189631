import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const DeleteKnowledgeConfigRequest = z.object({
  knowledgeId: z.string(),
  projectId: z.string(),
  
});

const DeleteKnowledgeConfigResponse = z.object({});


export type DeleteKnowledgeConfigRequest = z.infer<typeof DeleteKnowledgeConfigRequest>;
export type DeleteKnowledgeConfigResponse = z.infer<typeof DeleteKnowledgeConfigResponse>;

export const DeleteKnowledgeConfigDefinition = getFirebaseFunctionDefinition({
  path: "/projects/knowledge/delete",
  reqType: DeleteKnowledgeConfigRequest,
  resType: DeleteKnowledgeConfigResponse,
  isAuthRequired: true,
});
