import { z } from "zod";
import { CollectionDescriptor } from "./CollectionDescriptor";

export const Mail = z.object({
  to: z.string(),
  message: z.object({
    subject: z.string(),
    html: z.string(),
    text: z.string().optional(),
  }),
});

export type Mail = z.infer<typeof Mail>;

export const MailCollection: CollectionDescriptor<Mail> = {
  path: `mail`,
  schema: Mail,
};
