import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const DeleteTelegramIntegrationRequest = z.object({
  projectId: z.string(),
});

const DeleteProjctConfigIntegrationsRespone = z.object({});

export type DeleteTelegramIntegrationRequest = z.infer<
  typeof DeleteTelegramIntegrationRequest
>;

export const DeleteTelegramIntegrationDefinition = getFirebaseFunctionDefinition({
  path: "/integrations/telegram/delete",
  reqType: DeleteTelegramIntegrationRequest,
  resType: DeleteProjctConfigIntegrationsRespone,
  isAuthRequired: true,
});
