import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AddStayRequest = z.object({
  projectId: z.string(),
  name: z.string(),
  email: z.string().optional(),
  roomNumber: z.string().optional(),
  checkinDate: z.string(),
  checkoutDate: z.string().optional(),
  guestId: z.string().optional().nullable(),
});

const AddStayResponse = z.object({
  stayId: z.string(),
});

export type AddStayRequest = z.infer<typeof AddStayRequest>;
export type AddStayResponse = z.infer<typeof AddStayResponse>;

export const AddStayDefinition = getFirebaseFunctionDefinition({
  path: "/projects/stay/add",
  reqType: AddStayRequest,
  resType: AddStayResponse,
  isAuthRequired: true,
});
